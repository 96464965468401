import { useDispatch, useSelector } from "react-redux";
import { runPipelines } from "../../../Services/pipelines/pipeline.slice";
import { useState } from "react";
import { onChangeHandler, selectInputVal } from "../../../Services/apptemplates/apptemplates.slice";
import ElementContainer from "../ElementContainer";

const InputContainers = ({
                            element,
                            elmkey,
                            position,
                            indexarr,
                            clsName,
                            interactions
                        })=>{

    const dispatch = useDispatch();

    const generateProps = ()=>{
        let props = {...element.props};
        let actionableprops = {
            type: element.inputtype
        };
        for(let i=0; i< props.element; i++){
            if(props[i].value!=props[i].default){
                actionableprops[props[i].name] = props[i].value
            }
        }
        let interactions = {};
        for(let i=0; i< element.interactions.length; i++){
            if(element.interactions[i].name!="onChange"){
                interactions[element.interactions[i].name] = (e)=>{
                    dispatch(runPipelines({
                            key: elmkey,
                            pipelineid: element.interactions[i].pipeline,
                            schemamapping: element.interactions[i].schemamapping
                    }))
                }
            }
        }
        let combinedprops = {...actionableprops,...interactions}
        return combinedprops
    }

    // const [inputstate, setinputstate] = useState("");

    // const [elmActive, setElmActive] = useState(false);

    // const [elmFocus, setElmFocus] = useState(false);


    const inputval = useSelector((state)=>{return selectInputVal(state, elmkey, element.inputindex)});

    const onChangeAction = (value)=>{
            dispatch(onChangeHandler(
                {
                    key: elmkey,
                    index: element.inputindex,
                    val: value
                }
            ))
    }

    const onChangeFileAction = (e)=>{

            let file = e.target.files[0];
            if(file!=undefined){
                dispatch(onChangeHandler(
                    {
                        key: elmkey,
                        index: element.inputindex,
                        val: file.name,
                        file: file
                    }
                ))
            }
            
    }

    return (
        <>
            {element.tag=="input"&&
                <>
                    {element.inputtype=="file"?
                        <input
                            {...interactions}
                            {...generateProps()}
                            onChange={(e)=>{onChangeFileAction(e)}}
                            className={clsName}
                            name={element.name}
                        >
                        </input>:
                        <>
                            {element.inputtype=="checkbox"?
                                <>
                                    <input
                                        {...interactions}
                                        {...generateProps()}
                                        value={inputval}
                                        checked={inputval}
                                        onChange={(e)=>onChangeAction(e.target.checked)}
                                        className={clsName}
                                        name={element.name}
                                    >
                                    </input>
                                </>:
                                <>
                                    <input
                                        {...interactions}
                                        {...generateProps()}
                                        value={inputval}
                                        onChange={(e)=>onChangeAction(e.target.value)}
                                        className={clsName}
                                        name={element.name}
                                    >
                                    </input>
                                </>
                            }
                        </>

                    }
                    
                </>
            }
            {element.tag=="select"&&
                <>
                    <select
                        {...interactions}
                        {...generateProps()}
                        value={inputval}
                        onChange={(e)=>{onChangeAction(e.target.value)}}
                        className={clsName}
                        name={element.name}
                    >
                        {element.childs.length>0&&
                            <>
                                {element.childs.map((elm, index)=>{
                                    return(
                                        <ElementContainer
                                            elmkey={elmkey}
                                            element={elm}
                                            indexarr={[...indexarr, index]}
                                            position={[...position, index]}
                                        >
                                        </ElementContainer>
                                    )
                                })}
                            </>
                        }
                    </select>
                </>
            }
        </>
    )


}


export default InputContainers;