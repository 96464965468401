import { configureStore } from "@reduxjs/toolkit";
import AppSlice from "./Services/App.slice";
import pipelineSlice from "./Services/pipelines/pipeline.slice";
import apptemplatesSlice from "./Services/apptemplates/apptemplates.slice";
import environvariablesSlice from "./Services/environvariables/environvariables.slice";


export const store = configureStore({
    reducer:{
        toiler: AppSlice,
        pipeline: pipelineSlice,
        apptemplates: apptemplatesSlice,
        environvariables: environvariablesSlice,
    }
})