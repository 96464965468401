import logo from './logo.svg';
import './App.css';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWebsite, selectWebsite, getLogRoutes } from './Services/App.slice';
import AppContainer from './apps/appdesigner/AppContainer';
import StyleSheet from './apps/appdesigner/StyleSheet';
import { useLocation, useNavigate } from 'react-router-dom';
import { initNavigator } from './Services/pipelines/server/server-apps/router';
import { interfaceUrl } from './Services/env';
import { showError } from './Services/error.slice';
import "./Animations.css";

function App() {

    const dispatch = useDispatch();

    const website = useSelector(selectWebsite);

    useEffect(()=>{
        const host = window.location.host;
        const pathname = window.location.pathname;
        dispatch(getWebsite({
            host: host,
            route: pathname
        }));
        console.log("in the website fetch");
    },[])
    
    useEffect(()=>{
        if(website==undefined){
            return
        }
        if(website==null){
            return
        }
        if(website?.title!=""&&website?.title!=undefined){
            document.title = website.title;
        }
        if(website?.favicon!=""&&website?.favicon!=undefined){
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
              link = document.createElement('link');
              link.rel = 'icon';
              document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = website.favicon; 
        }
        if(website?.scripts?.length>0){
            for(let i=0; i<website.scripts.length; i++){
                let script = document.createElement("script");
                script.src = website.scripts[i];
                document.getElementsByTagName("head").appendChild(script);
            }
        }
    },[website])

    let location = useLocation();

    useEffect(()=>{
        if(location!=undefined&&website!=undefined){
            dispatch(
                        getLogRoutes({
                                        "website": website.website,
                                        "path": location.pathname
                                     })
                    )
        }
    },[location, website])

    const getFirstPath = (path)=>{
        let paths = path.split("/");
        return paths[1];
    }

    const navigate = useNavigate();

    useEffect(()=>{
        if(navigate!=undefined){
            const host = window.location.host;
            const pathname = window.location.pathname;
            if(host==interfaceUrl){
                let initpath = getFirstPath(pathname);
                if(initpath!=undefined&&initpath!=""){
                    initNavigator(navigate, initpath)
                }else{
                    dispatch(showError("Error accessing the website, you are not entering a valid URL."))
                }
            }else{
                let currentpath = "";
                initNavigator(navigate, currentpath);
            }
        }
    },[])

    return (
    <div className="App">
        {website!=null&&website!=undefined&&
            <>
                <StyleSheet></StyleSheet>
                <AppContainer
                    templateid={website.template}
                    indexarr={[]}
                    elmkey={""}
                    type="html"
                >
                </AppContainer>
            </>
            
        }
    </div>
);
}

export default App;
