import * as utils from "./utils";
import * as http_client from "./server-apps/https";
import * as transform from "./server-apps/transforms";
import * as jsonService from "./server-apps/json";
import * as jwtService from "./server-apps/jwt";
import * as mathService from "./server-apps/math";
import * as regexService from "./server-apps/regex";
import * as conditional from "./server-apps/conditional";
import * as localstorageservice from "./server-apps/localstorage";
import * as sessionstorageservice from "./server-apps/sessionstorage";
import * as routerService from "./server-apps/router";
import * as windowService from "./server-apps/window";
import * as _ from "lodash";
import {StageError} from "./server-apps/errors";
import {
    getLocalStorageSchema, 
    getSessionStorage, 
} from "../../apptemplates/apptemplates.slice";

export const loadTemplateVariables = (
    type,
    templateid,
    getState,
    delineatedPipeline,
)=>{
    if(type=="main"){
        let stateCopy = getState();
        let templatevariablesCopy = JSON.parse(JSON.stringify(stateCopy.apptemplates.appvariables));
        let schema = [];
        let output = {};
        if(templatevariablesCopy[templateid]!=undefined){
            let varaibleschema = templatevariablesCopy[templateid].schema;
            output["variable"] = varaibleschema[0].value
            schema.push(varaibleschema[0]);
        }
        //load inputs
        let appinputs = JSON.parse(JSON.stringify(stateCopy.apptemplates.appinputs));
        let templateinputs = appinputs[templateid];
        if(templateinputs!=undefined){
            let inputschema = {
                "key": "inputs",
                "label": "Inputs",
                "type": "object",
                "subschema": [...templateinputs],
                "value": {}
            }
            schema.push(inputschema)
            output["inputs"] = {};
        }
        //load session storage
        let localStorageSchema = getLocalStorageSchema();
        schema.push(localStorageSchema[0]);
        output["localstorage"] = {};
        //load session storage
        let sessionStorageSchema = getSessionStorage();
        schema.push(sessionStorageSchema[0]);
        output["sessionstorage"] = {};
        delineatedPipeline["template"] = {
                                          outputschema: schema,
                                          output: output
                                         };
        
        return delineatedPipeline;
    }else{

    }

}

const getTemplatePipeline = (getState, key, pipelineid)=>{
    let apptemplates = getState().apptemplates;
    let templatepipelines = apptemplates.templatepipelines;
    let keypipelines = templatepipelines[key];
    let pipelineindex = _.findIndex(keypipelines, (pipe)=>{return pipe._id==pipelineid});
    if(pipelineindex>-1){
        let pipeline = keypipelines[pipelineindex]
        return pipeline
    }else{
        return false;
    }
    
}

const getStage = (pipeline, stageindex, pipelinetype, loopstage)=>{
    for(let i=0; i< pipeline.length; i++){
        if(pipeline[i].stageindex==stageindex){
            return {
                stage: pipeline[i],
                pipelinetype: pipelinetype,
                loopstage:loopstage
            }
        }
        if(pipeline[i].type=="conditional"){
            let conditions = pipeline[i].conditions;
            for(let j=0; j<conditions.length; j++){
                let conditionpipeline = conditions[j].pipeline;
                let res = getStage(conditionpipeline,stageindex, "conditional");
                if(res!=false){
                    return res;
                }
            }
        }
        if(pipeline[i].type=="loop"){
          let loop = pipeline[i].loop;
          let res = getStage(loop, stageindex,"loop",pipeline[i]);
          if(res!=false){
            return res;
          }
        }
    }
    return false;
}

const checkForResponse = (pipeline, response_action)=>{
    let responses = []
    utils.searchPipeline(pipeline,{"action":response_action},responses);
    if(responses.length>0){
        return false;
    }else{
        return true;
    }
}

const isStageResponse = (stage)=>{
    if(stage.action=="pipeline_response"){
        return true;
    }else if(
                stage.action=="http_response_json"||
                stage.action=="http_response_file"||
                stage.action=="http_response_redirect"||
                stage.action=="http_response_error"
    ){
        return true;
    }
    return false;
}

const executeSingleStage = async( 
                                 stage, 
                                 delineatedPipeline, 
                                 key, 
                                 dispatch, 
                                 getState,
                                 pipelineid
                                )=>{
    let actionResult;
    let pipelineResult;
    if(stage.appid=="http_client"){
        actionResult = await http_client.executeAction(stage,delineatedPipeline,true);
        utils.updateOutput(delineatedPipeline,stage.stageindex,actionResult);
    }else if(stage.appid=="transform"){
        await transform.executeAction(stage, delineatedPipeline, key, dispatch, pipelineid);
    }else if(stage.appid=="json"){
        actionResult = await jsonService.executeAction(stage, delineatedPipeline);
        utils.updateOutput(delineatedPipeline, stage.stageindex, actionResult);
    }else if(stage.appid=="regex"){
        actionResult = await regexService.executeAction(stage, delineatedPipeline);
        utils.updateOutput(delineatedPipeline, stage.stageindex, actionResult);
    }else if(stage.appid=="jwt"){
        actionResult = await jwtService.executeAction(stage, delineatedPipeline);
        utils.updateOutput(delineatedPipeline, stage.stageindex, delineatedPipeline);
    }else if(stage.appid=="math"){
        actionResult = await mathService.executeAction(stage, delineatedPipeline);
        utils.updateOutput(delineatedPipeline, stage.stageindex, actionResult);
    }else if(stage.appid=="localstorage"){
        actionResult = await localstorageservice.executeAction(stage, delineatedPipeline, key);
        utils.updateOutput(delineatedPipeline, stage.stageindex, actionResult);
    }else if(stage.appid=="sessionstorage"){
        actionResult = await sessionstorageservice.executeAction(stage, delineatedPipeline);
        utils.updateOutput(delineatedPipeline, stage.stageindex, actionResult);
    }else if(stage.appid=="router"){
        actionResult = await routerService.executeAction(stage, delineatedPipeline,false);
        utils.updateOutput(delineatedPipeline, stage.stageindex, actionResult);
    }else if(stage.appid=="window"){
        actionResult = await windowService.executeAction(stage, delineatedPipeline);
        utils.updateOutput(delineatedPipeline, stage.stageindex, actionResult);
    }else if(stage.apptype=="template"){
        let mpipelineid = stage.action;
        let pipeline = getTemplatePipeline(getState, key, mpipelineid);
        if(pipeline!=false){
            let callback = (resp)=>{
                actionResult = resp;
            }
            let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, delineatedPipeline);
            let delineatedpipeline = {};
            loadTemplateVariables("main", key, getState, delineatedpipeline, "pipeline", "", {});
            await executeLoop(
                pipeline,
                input,
                delineatedpipeline,
                callback,
                key,
                dispatch,
                getState
            );
            utils.updateOutput(delineatedPipeline, stage.stageindex, actionResult);
        }
    }
    else{
        
    }
    return {actionResult, pipelineResult};
}

const executeSingleStageUi = async(
                                    stage, 
                                    delineatedPipeline, 
                                    key, 
                                    dispatch,
                                    getState  
                                  )=>{
    let actionResult;
    if(stage.app==""){
        utils.updateOutput(delineatedPipeline, stage.stageindex,undefined);
    }else if(stage.appid=="conditions"){
        let actionResult = await conditional.executeAction(stage, delineatedPipeline);
        utils.updateOutput(delineatedPipeline, stage.stageindex, actionResult);
    }else if(stage.appid=="http_client"){
        actionResult = await http_client.executeAction(stage,delineatedPipeline,true);
        utils.updateOutput(delineatedPipeline,stage.stageindex,actionResult);   
    }else if(stage.appid=="transform"){
        await transform.executeAction(stage, delineatedPipeline, key, dispatch);
    }else if(stage.appid=="manual_trigger"){
        actionResult = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, delineatedPipeline);
        utils.updateOutput(delineatedPipeline,stage.stageindex, actionResult);
    }else if(stage.appid=="json"){
        actionResult = await jsonService.executeAction(stage, delineatedPipeline);
        utils.updateOutput(delineatedPipeline, stage.stageindex, actionResult);
    }else if(stage.appid=="regex"){
        actionResult = await regexService.executeAction(stage, delineatedPipeline);
        utils.updateOutput(delineatedPipeline, stage.stageindex, actionResult);
    }else if(stage.appid=="jwt"){
        actionResult = await jwtService.executeAction(stage, delineatedPipeline);
        utils.updateOutput(delineatedPipeline, stage.stageindex, actionResult);
    }else if(stage.appid=="math"){
        actionResult = await mathService.executeAction(stage, delineatedPipeline);
        utils.updateOutput(delineatedPipeline, stage.stageindex, actionResult);
    }else if(stage.appid=="localstorage"){
        actionResult = await localstorageservice.executeAction(stage, delineatedPipeline);
        utils.updateOutput(delineatedPipeline, stage.stageindex, actionResult);
    }else if(stage.appid=="sessionstorage"){
        actionResult = await sessionstorageservice.executeAction(stage, delineatedPipeline);
        utils.updateOutput(delineatedPipeline, stage.stageindex, actionResult);
    }else if(stage.appid=="router"){
        actionResult = await routerService.executeAction(stage, delineatedPipeline, true);
        utils.updateOutput(delineatedPipeline, stage.stageindex, actionResult)
    }else if(stage.appid=="window"){
        actionResult = await windowService.executeAction(stage, delineatedPipeline);
        utils.updateOutput(delineatedPipeline, stage.stageindex, actionResult);
    }else if(stage.type=="loop"){
        let val = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, delineatedPipeline);
        utils.updateOutput(delineatedPipeline, stage.stageindex,val,stage.currentindex);
    }else if(stage.apptype=="template"){
        let mpipelineid = stage.action;
        let pipeline = getTemplatePipeline(getState, key, mpipelineid);
        if(pipeline!=false){
            let callback = (resp)=>{
                actionResult = resp;
            }
            let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, delineatedPipeline);
            let delineatedpipeline = {};
            loadTemplateVariables("main", key, getState, delineatedpipeline, "pipeline", "", {});
            /**
             * pipeline, params, delineatedPipeline, callback, key, dispatch, getState
             */
            await executeLoop(
                pipeline,
                input,
                delineatedpipeline,
                callback,
                key,
                dispatch,
                getState
            );
            utils.updateOutput(delineatedPipeline, stage.stageindex, actionResult);
        }
        
    }
    else{
        
    }
}

export const executeStage = async (
                                    pipeline, 
                                    delineatedPipeline, 
                                    stageindex, 
                                    key, 
                                    dispatch, 
                                    pipelineid,
                                    getState
                                )=>{
    let pipelinetype = "main";
    let res = getStage(pipeline,stageindex,pipelinetype);
    let stage = res.stage;
    pipelinetype = res.pipelinetype;
    let actionResult;
    if(stageindex==1){
        try{
            if(stage.appid=="manual_trigger"){
                actionResult = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, delineatedPipeline);
                utils.updateOutput(delineatedPipeline, stage.stageindex, actionResult);
             }
        }catch(err){
            throw new StageError(stageindex, err.message);
        }
    }else{
        try{            
            await executeSingleStageUi(
                                        stage, 
                                        delineatedPipeline, 
                                        key, 
                                        dispatch, 
                                        getState
                                    );
        }catch(e){
            throw new StageError(stageindex, e.message);
        }
    }
}

export const executeLoop = async(pipeline, params, delineatedPipeline, callback, key, dispatch, getState)=>{
    let loop = pipeline.pipeline[0];
    let loopoutput = params;
    utils.updateOutput(delineatedPipeline, 1,loopoutput);
    let executablePipeline = [...pipeline.pipeline];
    executablePipeline.splice(0,1);
    let res = await executePipelineV1(executablePipeline,delineatedPipeline,false, "main", key, dispatch, getState);
    callback(res);
}

export const executePipelineV1 = async(
                                        pipeline,
                                        delineatedPipeline,
                                        ui,
                                        pipelinetype,
                                        key,
                                        dispatch,
                                        getState)=>{
    let pipelineResult;
    for(let i=0; i< pipeline.length; i++){
        let stage = pipeline[i];
        let isResponse = isStageResponse(stage);
        if(!isResponse){
            try{
                let actionResult;
                if(stage.type=="single"){
                    ({actionResult, pipelineResult} = await executeSingleStage(
                                                                                stage,
                                                                                delineatedPipeline, 
                                                                                key, 
                                                                                dispatch,
                                                                                getState,
                                                                                ""
                                                                            ));
                    if(pipelineResult?.pill){
                        break;
                    }
                }else if(stage.type=="conditional"){
                    let conditions = stage.conditions;
                    let conditionalres = false;
                    for(let j =0 ; j < conditions.length; j++){
                        let conditionpipeline = conditions[j].pipeline;
                        let conditionstage = conditionpipeline[0];
                        let res = await conditional.executeAction(conditionstage, delineatedPipeline);
                        utils.updateOutput(delineatedPipeline,conditionstage.stageindex,res);
                        if(res.result){
                            let conditionres = await executePipelineV1(
                                                                        conditionpipeline.splice(1, (conditionpipeline.length-1)), 
                                                                        delineatedPipeline, 
                                                                        ui,  
                                                                        "conditional",
                                                                        key,
                                                                        dispatch,
                                                                        getState
                                                                     )
                            if(conditionres!=undefined){
                                pipelineResult = conditionres;
                                conditionalres = true;
                                break;
                            }
                            if(conditionres?.pill){
                                conditionalres = true;
                                pipelineResult = conditionres; 
                                break;
                            }
                        }
                    }
                    if(conditionalres){
                        break;
                    }
                }else if(stage.type=="loop"){
                    let loop = stage.loop;
                    if(ui){
                        let val = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, delineatedPipeline);
                        utils.updateOutput(delineatedPipeline, stage.stageindex,val,"");
                        let pipelinedelayed = false;
                        for(let j=0; j < stage.currentindex+1; j++){
                            // update the current index in the delineated pipeline
                            utils.updateOutput(delineatedPipeline,stage.stageindex,val,j);
                            let pres = await executePipelineV1(
                                                                loop, 
                                                                delineatedPipeline, 
                                                                ui, 
                                                                "loop",
                                                                key,
                                                                dispatch,
                                                                getState
                                                              );
                            if(pres?.pill){
                                pipelinedelayed = true;
                                break;
                            }
                        }
                        if(pipelinedelayed){
                            break;
                        }
                    }
                }
            }catch(e){
                utils.updateError(delineatedPipeline, stage.stageindex, e.toString());
                throw e;
            }
        }else{
            pipelineResult = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, delineatedPipeline);
            break;
        }
    }
    return pipelineResult;
}