import * as utils from "../utils";
import * as _ from "lodash";
import date from "date-and-time";
import {transformvar} from "../../../apptemplates/apptemplates.slice";
import { commitEnvironVar } from "../../../environvariables/environvariables.slice";

const getValInMilliSeconds = (unit, value)=>{
    if(unit=="days"){
        let val = value*24*60*60*1000;
        return val;
    }else if(unit=="hours"){
        let val = value*60*60*1000;
        return val;
    }else if(unit=="minutes"){
        let val = value*60*1000;
        return val;
    }else if(unit=="seconds"){
        let val = value*1000;
        return val;
    }
}

const datetimeActions = async (stage, pipeline)=>{
    if(stage.action=="now"){
        let output = new Date();
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": output}
        )
    }else if(stage.action=="addtime"){
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let datetime = input.datetime;
        let unit = input.unit;
        let value = input.value;
        let valMilliSeconds = getValInMilliSeconds(unit, value);
        let output = new Date();
        output.setTime(datetime.getTime()+valMilliSeconds);
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": output}
        )

    }else if(stage.action=="subtracttime"){
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let datetime = input.datetime;
        let unit = input.unit;
        let value = input.value;
        let valMilliSeconds = getValInMilliSeconds(unit, value);
        let output = new Date();
        output.setTime(datetime.getTime()-valMilliSeconds);
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": output}
        )
    }else if(stage.action=="parse"){
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let datestring = input.string;
        let format = input.format;
        let output = date.parse(datestring, format);
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": output}
        )
    }else if(stage.action=="format"){
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let datetime = input.datetime;
        let format = input.format;
        let output = date.format(datetime, format);
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": output}
        )
    }else if(stage.action=="getdate"){
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let datetime = input.datetime;
        let output = datetime.getDate();
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": output}
        )
    }else if(stage.action=="getday"){
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let datetime = input.datetime;
        let output = datetime.getDay();
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": output}
        )
    }else if(stage.action=="getfullyear"){
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let datetime = input.datetime;
        let output = datetime.getFullYear();
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": output}
        )
    }else if(stage.action=="gethours"){
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let datetime = input.datetime;
        let output = datetime.getHours();
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": output}
        )
    }else if(stage.action=="getmilliseconds"){
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let datetime = input.datetime;
        let output = datetime.getMilliSeconds();
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": output}
        )
    }else if(stage.action=="getmonth"){
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let datetime = input.datetime;
        let output = datetime.getMonth();
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": output}
        )
    }else if(stage.action=="getseconds"){
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let datetime = input.datetime;
        let output = datetime.getSeconds();
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": output}
        )
    }else if(stage.action=="getime"){
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let datetime = input.datetime;
        let output = datetime.getTime();
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": output}
        )
    }else if(stage.action=="setdate"){
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let datetime = input.datetime;
        datetime.setDate(input.date);
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": datetime}
        )
    }else if(stage.action=="setfullyear"){
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let datetime = input.datetime;
        datetime.setFullYear(input.year);
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": datetime}
        )
    }else if(stage.action=="sethours"){
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let datetime = input.datetime;
        datetime.setHours(input.hours);
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": datetime}
        )
    }else if(stage.action=="setmilliseconds"){
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let datetime = input.datetime;
        datetime.setMilliseconds(input.milliseconds);
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": datetime}
        )
    }else if(stage.action=="setminutes"){
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let datetime = input.datetime;
        datetime.setMinutes(input.minutes);
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": datetime}
        )
    }else if(stage.action=="setmonth"){
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let datetime = input.datetime;
        datetime.setMonth(input.month);
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": datetime}
        )
    }else if(stage.action=="setseconds"){
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let datetime = input.datetime;
        datetime.setSeconds(input.seconds);
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": datetime}
        )
    }else if(stage.action=="settime"){
        let input = await utils.parseSchema(stage.inputschema , stage.inputschemamapping, pipeline);
        let datetime = input.datetime;
        datetime.setTime(input.time);
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": datetime}
        )
    }
}

const stringActions = async (stage, pipeline)=>{
    if(stage.action=="uppercase"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let output =inputval.string.toUpperCase();
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": output}
        );

    }else if(stage.action=="lowercase"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let output = inputval.string.toLowerCase();
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": output }
        )
    }else if(stage.action=="split"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        if(inputval.delimiter==undefined){
            let output = inputval.string.split("");
            utils.updateOutput(
                pipeline,
                stage.stageindex,
                {"result": output }
            )
        }else{
            let output = inputval.string.split(inputval.delimiter);
            utils.updateOutput(
                pipeline,
                stage.stageindex,
                {"result": output }
            )
        }
        
    }else if(stage.action=="replace"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let output = inputval.string.replace(inputval.pattern, inputval.replacement);
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": output }
        )

    }else if(stage.action=="replaceall"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let output = inputval.string.replaceAll(inputval.pattern, inputval.replacement);
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": output }
        )
    }else if(stage.action=="concat"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        if(inputval.delimiter==undefined){
            let output = "".concat(inputval.strings);
            utils.updateOutput(
                pipeline,
                stage.stageindex,
                {"result": output}
            )
        }else{
            let output = inputval.delimiter.concat(inputval.strings);
            utils.updateOutput(
                pipeline,
                stage.stageindex,
                {"result": output}
            )
        }
    }else if(stage.action=="endswith"){
        let inputval =await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let output = inputval.string.endsWith(inputval.searchstring);
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": output}
        )
    }else if(stage.action=="substring"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        if(inputval.indexstop==undefined){
            let output = inputval.string.substring(inputval.indexstart);
            utils.updateOutput(
                pipeline,
                stage.stageindex,
                {"result": output}
            )
        }else{
            let output = inputval.string.substring(inputval.indexstart, inputval.indexstop);
            utils.updateOutput(
                pipeline,
                stage.stageindex,
                {"result": output}
            )
        }

    }else if(stage.action=="startswith"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let output = inputval.string.startsWith(inputval.searchstring);
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": output}
        )
    }else if(stage.action=="trim"){
        let inputval =await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let output = inputval.string.trim();
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": output}
        )
    }
}

const booleanActions = async (stage, pipeline)=>{
    if(stage.action=="and"){
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = input.value1&&input.value2;
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": res}
        )
    }else if(stage.action=="or"){
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = input.value1||input.value2;
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": res}
        )
    }else if(stage.action=="not"){
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = !input.value1;
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": res}
        )
    }
}

const getIndexArr = (executablePipeline, val)=>{
    let parsedVal = utils.parseSchemaKey(val);
    let value;
    let indexarr = [];
    let stageindex;
    for(let i=0; i < parsedVal.length; i++){
        if(i==0){
            // handle transforms
            // for loops
            // add if check if the stage output is reference or stage is value.
            stageindex = parsedVal[i];
            if(executablePipeline[parsedVal[i]].type=="reference"){
                value = utils.getReferenceSchema(parsedVal[i], executablePipeline);
            }else{
                if(parsedVal[i+1]=="current_index"){
                    let val = executablePipeline[parsedVal[i]].output.array_to_loop[executablePipeline[parsedVal[i]].current_index];
                    let valueschema = [];
                    utils.generateSchema(val, valueschema);
                    if(valueschema.length==0){
                        value = val;
                        i=i+1;
                    }else{
                        value = valueschema;
                        i=i+1;
                    }
                }else{
                    value = executablePipeline[parsedVal[i]].outputschema;
                }
            }   
        }else if(i==(parsedVal.length-1)){
            let concernedkeyindex = _.findIndex(value, (k)=>{return k.key==parsedVal[i]});
            indexarr.push(concernedkeyindex);
        }
        else{
            let concernedkeyindex = _.findIndex(value, (k)=>{return k.key==parsedVal[i]});
            value = value[concernedkeyindex].subschema;
            indexarr.push(concernedkeyindex);
        }
    }
    return {
        stageindex: stageindex,
        indexarr: indexarr
    };
}

// todo: update the schema too instead of generating new schema;
const updateObjByIndexArr = (executablePipeline, stageindex, indexarr, newobject, action)=>{
    let newOutput = executablePipeline[stageindex].output;
    let newOutputSchema = executablePipeline[stageindex].outputschema;
    for(let i=0; i< indexarr.length; i++){
        if(i==(indexarr.length-1)){
            try{
                let concernedkeyindex = indexarr[i];
                if(action=="setkey"){
                    newOutput[newOutputSchema[concernedkeyindex].key] = newobject;
                    let newkeyschema = [];
                    utils.generateSchema(newobject, newkeyschema);
                    if(newkeyschema[0]?.key!=undefined){
                        newOutputSchema[concernedkeyindex] = {...newOutputSchema[concernedkeyindex],
                                                              value: newobject,
                                                              subschema: newkeyschema
                                                             }
                    }else{
                        newOutputSchema[concernedkeyindex] = {...newOutputSchema[concernedkeyindex],
                                                              value: newobject,
                                                              subschema:[]
                                                              }
                    }
                }else if(action=="addkey"){
                    newOutput[newOutputSchema[concernedkeyindex].key] = {...newOutput[newOutputSchema[concernedkeyindex].key],
                                                                         ...newobject
                                                                        }
                    let newkeyschema = [];
                    utils.generateSchema(newobject, newkeyschema);
                    let keysubschema = [...newOutputSchema[concernedkeyindex].subschema, ...newkeyschema];
                    newOutputSchema[concernedkeyindex] = {...newOutputSchema[concernedkeyindex],
                                                          subschema: keysubschema
                                                         }
                    
                }else if(action=="deletekey"){
                    delete newOutput[newOutputSchema[concernedkeyindex].key][newobject];
                    let deletedKeyIndex = _.findIndex(newOutputSchema[concernedkeyindex].subschema, (sc)=>{return sc.key==newobject});
                    let newsubschema = newOutputSchema[concernedkeyindex].subschema;
                    let newsubval = newOutputSchema[concernedkeyindex].value;
                    delete newsubval[newobject];
                    newsubschema[deletedKeyIndex] = {...newsubschema[deletedKeyIndex],
                                                     deleted: true
                                                    }
                    newOutputSchema[concernedkeyindex] = {...newOutputSchema[concernedkeyindex],
                                                          subschema: newsubschema,
                                                          value: newsubval
                                                         }
                }
            }catch(error){
                throw error;
            }
        }else{
            let concernedkeyindex = indexarr[i];
            if(newOutputSchema[indexarr[i]].type=="array"){
                // todo: handle array schema
                if((i+1)==(indexarr.length-1)){
                    newOutput = newOutput[indexarr[i]].value[indexarr[i+1]];
                    break;
                }else{
                    newOutput = newOutput[newOutputSchema[concernedkeyindex].key][indexarr[i+1]];
                    let newsch = utils.generateSchema(newOutput);
                    newOutputSchema = newsch;
                    i = i+1
                }
            }else{
                newOutput = newOutput[newOutputSchema[concernedkeyindex].key];
                newOutputSchema = newOutputSchema[concernedkeyindex].subschema;
            }
        }
    }
}

const getObjectKey = (object, key)=>{
    let keys = Object.keys(object);
    let keyIndex = _.findIndex(keys, (k)=>{return key==k});
    return keyIndex;   
}

const getReferenceIndexArr = (pipeline, stageindex, indexarr)=>{
        if(pipeline[stageindex].type=="reference"){
            let parentIndexarr = pipeline[stageindex].valIndexArr;
            stageindex = pipeline[stageindex].refStage;
            indexarr = [...parentIndexarr, ...indexarr];
            let res = getReferenceIndexArr(pipeline, stageindex, indexarr);
            return res;
        }else{
            return {
                stageindex: stageindex,
                indexarr: indexarr
            }
        }
}

const  updateObjectRef = async (
                                    pipeline, 
                                    schemaKey, 
                                    action, 
                                    actionkey, 
                                    actionvalue,
                                    templatekey,
                                    dispatch,
                                    pipelineid
                                )=>{
        let steps = utils.parseSchemaKey(schemaKey);
        let updateObj;
        let stageindex;
        let indexarr;
        let key = steps[steps.length-1];
        if(pipeline[steps[0]].type=="reference"){
            let res = getReferenceIndexArr(pipeline, pipeline[steps[0]].refStage, pipeline[steps[0]].valIndexArr);
            if(action=="setkey"){
                let inputval = await utils.getValfromPipe(schemaKey, pipeline);
                inputval[actionkey] = actionvalue;
                updateObjByIndexArr(
                                        pipeline, 
                                        res.stageindex, 
                                        res.indexarr, 
                                        inputval, 
                                        "setkey"
                                    );
                // let updatedRefStageSchema = [];
                // utils.generateSchema(pipeline[res.stageindex].output, updatedRefStageSchema);
                // dispatch to templates
                if(res.stageindex=="template"){
                    try{
                        dispatch(
                            transformvar({
                                childkey: templatekey,
                                schema: [...pipeline[res.stageindex].outputschema]
                            })
                        )
                    }catch(error){
                        throw error;
                    }
                    
                }else if(res.stageindex=="___envvar___"){
                    dispatch(commitEnvironVar({
                        pipelineid: pipelineid,
                        envvars: [...pipeline[res.stageindex].outputschema]
                    }));
                }
                
                pipeline[res.stageindex] = {...pipeline[res.stageindex],
                                            outputschema: [...pipeline[res.stageindex].outputschema]
                                            };
                updateObj = {
                    stageindex: res.stageindex,
                    output: pipeline[res.stageindex].output,
                    outputschema: [...pipeline[res.stageindex].outputschema]
                }
                let currentRes = getIndexArr(pipeline, schemaKey);
                stageindex = currentRes.stageindex;
                indexarr = currentRes.indexarr;
            }else if(action=="addkey"){
                let objectadd = {};
                objectadd[actionkey] = actionvalue;
                updateObjByIndexArr(
                                    pipeline, 
                                    res.stageindex, 
                                    res.indexarr, 
                                    objectadd, 
                                    "addkey"
                                    );
                // let updatedRefStageSchema = [];
                // utils.generateSchema(pipeline[res.stageindex].output, updatedRefStageSchema);
                // dispatch to templates
                if(res.stageindex=="template"){
                    dispatch(
                        transformvar({
                            childkey: templatekey,
                            schema: [...pipeline[res.stageindex].outputschema]
                        })
                    )
                }else if(res.stageindex=="___envvar___"){
                    //update the envvironment variables
                    dispatch(commitEnvironVar({
                        pipelineid: pipelineid,
                        envvars: [...pipeline[res.stageindex].outputschema]
                    }));

                }
                pipeline[res.stageindex] = {...pipeline[res.stageindex],
                                            outputschema: [...pipeline[res.stageindex].outputschema]
                                            };
                updateObj = {
                    stageindex: res.stageindex,
                    output: pipeline[res.stageindex].output,
                    outputschema: [...pipeline[res.stageindex].outputschema]
                }
                let currentRes = getIndexArr(pipeline, schemaKey);
                stageindex = currentRes.stageindex;
                indexarr = currentRes.indexarr;
            }else if(action=="deletekey"){
                updateObjByIndexArr(
                                    pipeline, 
                                    res.stageindex, 
                                    res.indexarr, 
                                    actionkey, 
                                    "deletekey"
                                    );
                updateObj = {
                                stageindex: res.stageindex,
                                output: pipeline[res.stageindex].output,
                                outputschema: pipeline[res.stageindex].outputschema
                            }
                // dispatch to templates
                if(res.stageindex=="template"){
                    dispatch(
                        transformvar({
                            childkey: templatekey,
                            schema: [...pipeline[res.stageindex].outputschema]
                        })
                    )
                }else if(res.stageindex=="___envvar___"){
                    dispatch(commitEnvironVar({
                        pipelineid: pipelineid,
                        envvars: [...pipeline[res.stageindex].outputschema]
                    }));
                }
                let currentRes = getIndexArr(pipeline, schemaKey);
                stageindex = currentRes.stageindex;
                indexarr = currentRes.indexarr; 
            }
        }else{
            if(action=="setkey"){
                let inputval = await utils.getValfromPipe(schemaKey, pipeline);
                inputval = JSON.parse(JSON.stringify(inputval));
                inputval[actionkey] = actionvalue;
                let res = getIndexArr(pipeline, schemaKey);
                updateObjByIndexArr(
                                    pipeline,
                                    res.stageindex,
                                    res.indexarr, 
                                    inputval, 
                                    "setkey"
                                    );
                // let updatedRefStageSchema = [];
                // utils.generateSchema(pipeline[res.stageindex].output,updatedRefStageSchema);
                // dispatch to templates
                if(res.stageindex=="template"){
                    try{
                        dispatch(
                            transformvar({
                                childkey: templatekey,
                                schema: [...pipeline[res.stageindex].outputschema]
                            })
                        )
                    }catch(error){
                        throw error;
                    }
                    
                }else if(res.stageindex=="___envvar___"){
                    dispatch(
                        commitEnvironVar({
                            pipelineid: pipelineid,
                            envvars: [...pipeline[res.stageindex].outputschema]
                        })
                    )
                }
                // pipeline[res.stageindex] = {
                //                             ...pipeline[res.stageindex],
                //                             outputschema: updatedRefStageSchema
                //                            }
                updateObj = {
                                stageindex: res.stageindex,
                                output: pipeline[res.stageindex].output,
                                outputschema: pipeline[res.stageindex].outputschema
                            }
                stageindex = res.stageindex;
                indexarr = res.indexarr;
            }else if(action=="addkey"){
                let objtoadd = {};
                objtoadd[actionkey] = actionvalue;
                let res = getIndexArr(pipeline, schemaKey);
                updateObjByIndexArr(
                                    pipeline, 
                                    res.stageindex, 
                                    res.indexarr, 
                                    objtoadd, 
                                    "addkey"
                                    );
                // let updatedRefStageSchema = [];
                // utils.generateSchema(pipeline[res.stageindex].output, updatedRefStageSchema);
                // dispatch to templates
                if(res.stageindex=="template"){
                    dispatch(
                        transformvar({
                            childkey: templatekey,
                            schema: [...pipeline[res.stageindex].outputschema]
                        })
                    )
                }else if(res.stageindex=="___envvar___"){
                    dispatch(
                        commitEnvironVar({
                            pipelineid: pipelineid,
                            envvars: [...pipeline[res.stageindex].outputschema]
                        })
                    )
                }
                // pipeline[res.stageindex] = {...pipeline[res.stageindex],
                //                             outputschema: [...]
                //                             }
                updateObj = {
                                stageindex: res.stageindex,
                                output: pipeline[res.stageindex].output,
                                outputschema: [...pipeline[res.stageindex].outputschema]
                            }
                stageindex = res.stageindex;
                indexarr = res.indexarr;  
            }else if(action=="deletekey"){
                let res = getIndexArr(pipeline, schemaKey);
                updateObjByIndexArr(
                                    pipeline, 
                                    res.stageindex, 
                                    res.indexarr, 
                                    actionkey,
                                    "deletekey"
                                    );
                // dispatch to templates
                if(res.stageindex=="template"){
                    dispatch(
                        transformvar({
                            childkey: templatekey,
                            schema: [...pipeline[res.stageindex].outputschema]
                        })
                    )
                }else if(res.stageindex=="___envvar___"){
                    dispatch(commitEnvironVar({
                       pipelineid: pipelineid,
                       envvars: [...pipeline[res.stageindex].outputschema] 
                    }))
                }
                updateObj = {
                                stageindex: res.stageindex,
                                output: pipeline[res.stageindex].output,
                                outputschema: pipeline[res.stageindex].outputschema
                            }
                stageindex = res.stageindex;
                indexarr = res.indexarr;
            }
        }
        return {
            updateObj: updateObj,
            stageindex: stageindex,
            indexarr: indexarr,
            key: key
        }
}

const updateArrayRef = async (pipeline, schemaKey, obj, templatekey, dispatch, pipelineid)=>{
    let steps = utils.parseSchemaKey(schemaKey);
    let updateObj;
    let stageindex;
    let indexarr;
    let key = steps[steps.length-1];
    if(pipeline[steps[0]].type=="reference"){
        let res = getReferenceIndexArr(pipeline, pipeline[steps[0]].refStage, pipeline[steps[0]].valIndexArr);
        updateObjByIndexArr(pipeline, res.stageindex, res.indexarr, obj, "setkey");
        // let updatedRefStageSchema = [];
        // utils.generateSchema(pipeline[res.stageindex].output, updatedRefStageSchema);
        // dispatch to templates
        if(res.stageindex=="template"){
            dispatch(
                transformvar({
                    childkey: templatekey,
                    schema: [...pipeline[res.stageindex].outputschema]
                })
            )
        }else if(res.stageindex=="___envvar___"){
            dispatch(
                commitEnvironVar({
                    pipelineid: pipelineid,
                    envvars: [...pipeline[res.stageindex].outputschema]
                })
            )
        }   
        pipeline[res.stageindex] = {...pipeline[res.stageindex],
                                    outputschema: [...pipeline[res.stageindex].outputschema]
                                    };
        updateObj = {
            stageindex: res.stageindex,
            output: pipeline[res.stageindex].output,
            outputschema: [...pipeline[res.stageindex].outputschema]
        }
        let currentRes = getIndexArr(pipeline, schemaKey);
        stageindex = currentRes.stageindex;
        indexarr = currentRes.indexarr;
    }else{
        let res = getIndexArr(pipeline, schemaKey);
        updateObjByIndexArr(pipeline,res.stageindex,res.indexarr, obj, "setkey");
        // dispatch to templates
        if(res.stageindex=="template"){
            try{
                dispatch(
                    transformvar({
                        childkey: templatekey,
                        schema: [...pipeline[res.stageindex].outputschema]
                    })
                )    
            }catch(error){
                throw error;
            }
            
        }else if(res.stageindex=="___envvar___"){
            dispatch(
                commitEnvironVar({
                    pipelineid: pipelineid,
                    envvars: [...pipeline[res.stageindex].outputschema]
                })
            )
        }
        
        pipeline[res.stageindex] = {
                                    ...pipeline[res.stageindex],
                                    outputschema: [...pipeline[res.stageindex].outputschema]
                                    }
        updateObj = {
                        stageindex: res.stageindex,
                        output: pipeline[res.stageindex].output,
                        outputschema: [...pipeline[res.stageindex].outputschema]
                    }
        stageindex = res.stageindex;
        indexarr = res.indexarr;
    }

    return {
        updateObj: updateObj,
        stageindex: stageindex,
        indexarr: indexarr,
        key: key
    }
}

const objectActions = async (stage, pipeline, key, dispatch, pipelineid)=>{
    if(stage.action=="create"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            inputval
        )
        // return inputval;
    }else if(stage.action=="clone"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            inputval
        )
    }
    else if(stage.action=="deletekey"){
        let objmappingIndex = _.findIndex(stage.inputschemamapping, (sm)=>{return sm.key=="object"});
        let objmapping = stage.inputschemamapping[objmappingIndex];
        if(objmapping.action=="const"){
            let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
            let obj = inputval.object;
            delete obj[inputval.keyname]
            let resultObjSchema = [];
            utils.generateSchema(obj, resultObjSchema);
            let stageexecutiontime = new Date();
            pipeline[stage.stageindex] = {
                                            ...pipeline[stage.stageindex],
                                            output: obj,
                                            outputschema: resultObjSchema,
                                            executiontime: stageexecutiontime,
                                            type: "value"
                                         }
        }else{
            let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
            let actionkey = inputval.keyname;
            let res = await updateObjectRef(
                                        pipeline,
                                        objmapping.mapping.val, 
                                        "deletekey",
                                        actionkey, 
                                        undefined,
                                        key,
                                        dispatch,
                                        pipelineid
                                    );
            pipeline[stage.stageindex] = {
                                            key: res.key,
                                            refStage: res.stageindex,
                                            valIndexArr: res.indexarr,
                                            updateObj: res.updateObj,
                                            type: "reference"
                                         }
        }
    }else if(stage.action=="addkey"){
        let objmappingIndex = _.findIndex(stage.inputschemamapping, (sm)=>{return sm.key=="object"});
        let objmapping = stage.inputschemamapping[objmappingIndex];
        if(objmapping.action=="const"){
            let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
            let obj = inputval.object;
            obj[inputval.keyname] = inputval.keyvalue;
            let resultObjSchema = [];
            utils.generateSchema(obj, resultObjSchema);
            let stageexecutiontime = new Date();
            pipeline[stage.stageindex] = {
                                            ...pipeline[stage.stageindex],
                                            output: obj,
                                            outputschema: resultObjSchema,
                                            executiontime: stageexecutiontime,
                                            type: "value"
                                         }
        }else{
            let inputval =await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
            let actionkey = inputval.keyname;
            let actionvalue = inputval.keyvalue;
            let res = await updateObjectRef(
                                        pipeline,
                                        objmapping.mapping.val, 
                                        "addkey",
                                        actionkey, 
                                        actionvalue,
                                        key,
                                        dispatch,
                                        pipelineid
                                    );
            pipeline[stage.stageindex] = {
                                            key: res.key,
                                            refStage: res.stageindex,
                                            valIndexArr: res.indexarr,
                                            updateObj: res.updateObj,
                                            type: "reference"
                                         }
        }
    }else if(stage.action=="setkey"){
        let objmappingIndex = _.findIndex(stage.inputschemamapping, (sm)=>{return sm.key=="object"});
        let objmapping = stage.inputschemamapping[objmappingIndex];
        if(objmapping.action=="const"){
            let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
            let obj = inputval.object;
            obj[inputval.keyname] = inputval.keyvalue;
            let resultObjSchema = [];
            utils.generateSchema(obj, resultObjSchema);
            let stageexecutiontime = new Date();
            pipeline[stage.stageindex] = {
                                            ...pipeline[stage.stageindex],
                                            output: obj,
                                            outputschema: resultObjSchema,
                                            executiontime: stageexecutiontime,
                                            type: "value"
                                         }
        }else{
            let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
            let actionkey = inputval.keyname;
            let actionvalue = inputval.keyvalue;
            let res = await updateObjectRef(
                                        pipeline,
                                        objmapping.mapping.val, 
                                        "setkey",
                                        actionkey, 
                                        actionvalue,
                                        key,
                                        dispatch,
                                        pipelineid
                                        );
            pipeline[stage.stageindex] = {
                                            key: res.key,
                                            refStage: res.stageindex,
                                            valIndexArr: res.indexarr,
                                            updateObj: res.updateObj,
                                            type: "reference"
                                         }
        }
    }else if(stage.action=="keys"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let obj = inputval.object;
        let keys = obj.keys;
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": keys}
        )
    }else if(stage.action=="values"){
        let indexval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let obj = indexval.object;
        let values = obj.values();
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": values}
        )
    }else if(stage.action=="getkey"){
        let objmappingIndex = _.findIndex(stage.inputschemamapping, (sm)=>{return sm.key=="object"});
        let objmapping = stage.inputschemamapping[objmappingIndex];
        if(objmapping.action=="const"){
            let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
            let obj = inputval.object;
            let keyval = obj[inputval.keyname];
            let resultObj = {};
            resultObj[inputval.key] = keyval;
            let resultObjSchema = [];
            utils.generateSchema(resultObj, resultObjSchema);
            let stageexecutiontime = new Date();
            pipeline[stage.stageindex] = {...pipeline[stage.stageindex],
                                          output: resultObj,
                                          outputschema: resultObjSchema,
                                          executiontime: stageexecutiontime,
                                          type: "value"
                                         }
        }else{
            let schemaKey = objmapping.mapping.val;
            let indexval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
            let {stageindex,indexarr} = getIndexArr(pipeline, schemaKey);
            let keyIndex = getObjectKey(indexval.object, indexval.key);
            indexarr.push(keyIndex);
            pipeline[stage.stageindex]= {...pipeline[stage.stageindex],
                                         key: indexval.key,
                                         refStage: stageindex,
                                         valIndexArr: indexarr,
                                         type: "reference"
                                        }
        }
    }
}

const numberActions = async (stage, pipeline)=>{
    if(stage.action=="increment"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let num = inputval.number;
        let increment = inputval.increment;
        let finalval = num+increment;
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": finalval}
        )

    }else if(stage.action=="decrement"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let num = inputval.number;
        let decrement = inputval.decrement;
        let finalval = num-decrement;
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": finalval}
        )
    }else if(stage.action=="add"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let number1 = inputval.number1;
        let number2 = inputval.number2;
        let finalval = number1+number2;
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": finalval}
        )
    }else if(stage.action=="substract"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let number1 = inputval.number1;
        let number2 = inputval.number2;
        let finalval = number1 - number2;
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": finalval}
        )
    }else if(stage.action=="divide"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let divident = inputval.divident;
        let divisor = inputval.divisor;
        let finalval = divident/divisor;
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": finalval}
        )
    }else if(stage.action=="multiply"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let number1 = inputval.number1;
        let number2 = inputval.number2;
        let finalval = number1*number2;
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": finalval}
        )
    }else if(stage.action=="remainder"){
        let indexval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let divident = indexval.divident;
        let divisor = indexval.divisor;
        let finalval = divident%divisor;
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": finalval}
        )
    }else if(stage.action=="parseint"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let finalval = parseInt(inputval.string);
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": finalval}
        )
    }else if(stage.action=="parsefloat"){
        let inputval =await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let finalval = parseFloat(inputval.string);
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": finalval}
        )
    }
}

const arrayActions = async (stage, pipeline, templatekey, dispatch, pipelineid)=>{
    if(stage.action=="clone"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        utils.updateOutput(pipeline, stage.stageindex, inputval);
    }else if(stage.action=="getelement"){
        let objmappingIndex = _.findIndex(stage.inputschemamapping, (sm)=>{return sm.key=="array"});
        let objmapping = stage.inputschemamapping[objmappingIndex];
        if(objmapping.action=="const"){
            let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
            let obj = inputval.array;
            let keyval = obj[inputval.index];
            let resultObj = {};
            let indexkey = inputval.index
            resultObj[indexkey] = keyval;
            let resultObjSchema = [];
            utils.generateSchema(resultObj, resultObjSchema);
            let stageexecutiontime = new Date();
            pipeline[stage.stageindex] = {...pipeline[stage.stageindex],
                                          output: resultObj,
                                          outputschema: resultObjSchema,
                                          executiontime: stageexecutiontime,
                                          type: "value"
                                         }
        }else{
            let schemaKey = objmapping.mapping.val;
            let indexval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
            let {stageindex,indexarr} = getIndexArr(pipeline, schemaKey);
            indexarr.push(indexval.index);
            pipeline[stage.stageindex]= {...pipeline[stage.stageindex],
                                         key: indexval.index,
                                         refStage: stageindex,
                                         valIndexArr: indexarr,
                                         type: "reference"
                                        }
        }
    }else if(stage.action=="changeelement"){
        let objmappingIndex = _.findIndex(stage.inputschemamapping, (sm)=>{return sm.key=="array"});
        let objmapping = stage.inputschemamapping[objmappingIndex];
        if(objmapping.action=="const"){
            let inputval =await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
            let obj = inputval.array;
            obj[inputval.index] = inputval.item;
            let resultObjSchema = [];
            utils.generateSchema(obj, resultObjSchema);
            let stageexecutiontime = new Date();
            pipeline[stage.stageindex] = {
                                            ...pipeline[stage.stageindex],
                                            output: obj,
                                            outputschema: resultObjSchema,
                                            executiontime: stageexecutiontime,
                                            type: "value"
                                         }
        }else{
            let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
            let obj = inputval.array;
            obj[inputval.index] = inputval.item;
            let res = await updateArrayRef(
                                        pipeline,
                                        objmapping.mapping.val, 
                                        obj, 
                                        templatekey, 
                                        dispatch,
                                        pipelineid    
                                    );
            pipeline[stage.stageindex] = {
                                            key: res.key,
                                            refStage: res.stageindex,
                                            valIndexArr: res.indexarr,
                                            updateObj: res.updateObj,
                                            type: "reference"
                                         }
        }
    }else if(stage.action=="length"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let len = inputval.array.length;
        let res = {
            "result": len
        }
        utils.updateOutput(pipeline, stage.stageindex, res);
    }
    else if(stage.action=="push"){
        let objmappingIndex = _.findIndex(stage.inputschemamapping, (sm)=>{return sm.key=="array"});
        let objmapping = stage.inputschemamapping[objmappingIndex];
        if(objmapping.action=="const"){
            let inputval = await utils.parseSchema(
                                                    stage.inputschema, 
                                                    stage.inputschemamapping, 
                                                    pipeline
                                                  );
            let obj = inputval.array;
            obj.push(inputval.item);
            let resultObjSchema = [];
            utils.generateSchema(obj, resultObjSchema);
            let stageexecutiontime = new Date();
            pipeline[stage.stageindex] = {
                                            ...pipeline[stage.stageindex],
                                            output: obj,
                                            outputschema: resultObjSchema,
                                            executiontime: stageexecutiontime,
                                            type: "value"
                                         }
        }else{
            let inputval =await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
            let obj = inputval.array;
            obj.push(inputval.item);
            let res = await updateArrayRef(
                                        pipeline,
                                        objmapping.mapping.val, 
                                        obj, 
                                        templatekey, 
                                        dispatch,
                                        pipelineid    
                                    );
            pipeline[stage.stageindex] = {
                                            key: res.key,
                                            refStage: res.stageindex,
                                            valIndexArr: res.indexarr,
                                            updateObj: res.updateObj,
                                            type: "reference"
                                         }
        }
    }else if(stage.action=="removeelement"){
        let objmappingIndex = _.findIndex(stage.inputschemamapping, (sm)=>{return sm.key=="array"});
        let objmapping = stage.inputschemamapping[objmappingIndex];
        if(objmapping.action=="const"){
            let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
            let obj = inputval.array;
            obj.slice(inputval.index);
            let resultObjSchema = [];
            utils.generateSchema(obj, resultObjSchema);
            let stageexecutiontime = new Date();
            pipeline[stage.stageindex] = {
                                            ...pipeline[stage.stageindex],
                                            output: obj,
                                            outputschema: resultObjSchema,
                                            executiontime: stageexecutiontime,
                                            type: "value"
                                         }
        }else{
            let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
            let obj = inputval.array;
            obj.slice(inputval.index);
            let res = await updateArrayRef(
                                        pipeline,
                                        objmapping.mapping.val, 
                                        obj, 
                                        templatekey, 
                                        dispatch,
                                        pipelineid    
                                    );
            pipeline[stage.stageindex] = {
                                            key: res.key,
                                            refStage: res.stageindex,
                                            valIndexArr: res.indexarr,
                                            updateObj: res.updateObj,
                                            type: "reference"
                                         }
        }
    }else if(stage.action=="indexof"){
        let inputval =await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let arr = inputval.array;
        let index = _.findIndex(arr, inputval.item);
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": index}
        )
    }else if(stage.action=="unshift"){
        let objmappingIndex = _.findIndex(stage.inputschemamapping, (sm)=>{return sm.key=="array"});
        let objmapping = stage.inputschemamapping[objmappingIndex];
        if(objmapping.action=="const"){
            let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
            let obj = inputval.array;
            obj.unshift(inputval.item);
            let resultObjSchema = [];
            utils.generateSchema(obj, resultObjSchema);
            let stageexecutiontime = new Date();
            pipeline[stage.stageindex] = {
                                            ...pipeline[stage.stageindex],
                                            output: obj,
                                            outputschema: resultObjSchema,
                                            executiontime: stageexecutiontime,
                                            type: "value"
                                         }
        }else{
            let inputval =await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
            let obj = inputval.array;
            obj.push(inputval.item);
            let res = await updateArrayRef(
                                        pipeline,
                                        objmapping.mapping.val, 
                                        obj, 
                                        templatekey, 
                                        dispatch,
                                        pipelineid    
                                    );
            pipeline[stage.stageindex] = {
                                            key: res.key,
                                            refStage: res.stageindex,
                                            valIndexArr: res.indexarr,
                                            updateObj: res.updateObj,
                                            type: "reference"
                                         }
        }
    }else if(stage.action=="slice"){
        let objmappingIndex = _.findIndex(stage.inputschemamapping, (sm)=>{return sm.key=="array"});
        let objmapping = stage.inputschemamapping[objmappingIndex];
        if(objmapping.action=="const"){
            let inputval =await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
            let arr = inputval.array;
            if(inputval.start==undefined){
                arr.slice();
            }else{
                if(inputval.end==undefined){
                    arr.slice(inputval.start);
                }else{
                    arr.slice(inputval.start, inputval.end);
                }
            }
            let resultObjSchema = [];
            utils.generateSchema(arr, resultObjSchema);
            pipeline[stage.stageindex] = {
                                            ...pipeline[stage.stageindex],
                                            output: arr,
                                            outputschema: resultObjSchema,
                                            type: "value"
                                         }
        }else{
            let inputval =await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
            let arr = inputval.array;
            if(inputval.start==undefined){
                arr.slice();
            }else{
                if(inputval.end==undefined){
                    arr.slice(inputval.start);
                }else{
                    arr.slice(inputval.start, inputval.end);
                }
            }
            let resultObjSchema = [];
            utils.generateSchema(arr, resultObjSchema);
            pipeline[stage.stageindex] = {
                                            ...pipeline[stage.stageindex],
                                            output: arr,
                                            outputschema: resultObjSchema,
                                            type: "value"
                                         }
        }

    }else if(stage.action=="splice"){
        let objmappingIndex = _.findIndex(stage.inputschemamapping, (sm)=>{return sm.key=="array"});
        let objmapping = stage.inputschemamapping[objmappingIndex];
        if(objmapping.action=="const"){
            let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
            let arr = inputval.array;
            if(inputval.deletecount==undefined&&inputval.items==undefined){
                arr.splice(inputval.start);
            }else if(inputval.deletecount==undefined&&inputval.items!=undefined){
                // passing infinity
                arr.splice(inputval.start, Infinity, inputval.items);
            }else if(inputval.deletecount!=undefined&&inputval.items==undefined){
                arr.splice(inputval.start, inputval.deletecount);
            }else{
                arr.splice(inputval.start, inputval.deletecount, inputval.items);
            }
            let resultObjSchema = [];
            utils.generateSchema(arr, resultObjSchema);
            let stageexecutiontime = new Date();
            pipeline[stage.stageindex] = {
                                            ...pipeline[stage.stageindex],
                                            output: arr,
                                            outputschema: resultObjSchema,
                                            executiontime: stageexecutiontime,
                                            type: "value"
                                         }
        }else{
            let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
            let arr = inputval.array;
            if(inputval.deletecount==undefined&&inputval.items==undefined){
                arr.splice(inputval.start);
            }else if(inputval.deletecount==undefined&&inputval.items!=undefined){
                // passing infinity
                arr.splice(inputval.start, Infinity, inputval.items);
            }else if(inputval.deletecount!=undefined&&inputval.items==undefined){
                arr.splice(inputval.start, inputval.deletecount);
            }else{
                arr.splice(inputval.start, inputval.deletecount, inputval.items);
            }
            let res = await updateArrayRef(
                                        pipeline,
                                        objmapping.mapping.val, 
                                        arr, 
                                        templatekey, 
                                        dispatch,
                                        pipelineid
                                    );
            pipeline[stage.stageindex] = {
                                            key: res.key,
                                            refStage: res.stageindex,
                                            valIndexArr: res.indexarr,
                                            updateObj: res.updateObj,
                                            type: "reference"
                                         }
        }
    }else if(stage.action=="concat"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let arr1 = inputval.array1;
        let arr2 = inputval.array2;
        arr1.concat(arr2);
        utils.updateOutput(
            pipeline,
            stage.stageindex,
            {"result": arr1}
        )
    }
}

export const executeAction = async (stage, pipeline, key, dispatch, pipelineid)=>{
    if(stage.transformtype=="string"){
       try{
        let res = await stringActions(stage, pipeline);
        return res;
       }catch(error){
         throw error;
       } 
    }else if(stage.transformtype=="number"){
        try{
            let res = await numberActions(stage, pipeline);
            return res;
        }catch(error){
            throw error;
        }
    }else if(stage.transformtype=="array"){
        try{
            let res = await arrayActions(stage, pipeline, key, dispatch, pipelineid);
            return res;
        }catch(error){
            throw error;
        }
    }else if(stage.transformtype=="datetime"){
        try{
            let res = await datetimeActions(stage, pipeline);
            return res;
        }catch(error){
            throw error;
        }
    }else if(stage.transformtype=="object"){
        try{
            let res = await objectActions(stage, pipeline, key, dispatch, pipelineid);
            return res;
        }catch(error){
            throw error;
        }
    }else if(stage.transformtype=="boolean"){
        try{
            let res = await booleanActions(stage, pipeline, pipelineid);
            return res;
        }catch(error){
            throw error;
        }        

    }
}