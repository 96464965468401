import * as utils from "../utils";

export const executeAction = async(stage, pipeline)=>{
    if(stage.action=="abs"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.abs(inputval.input);
        return{
            "result": res
        }
    }else if(stage.action=="acos"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.acos(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="acosh"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.acosh(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="asin"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.asin(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="asinh"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.asinh(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="atan"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.atan(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="atan2"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.atan2(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="atanh"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.atanh(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="cbrt"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.cbrt(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="ceil"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.ceil(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="clz32"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.clz32(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="cos"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.cos(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="cosh"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.cosh(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="exp"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.exp(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="expm1"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.expm1(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="floor"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.floor(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="fround"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.fround(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="hypot"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.atan(...inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="log"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.log(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="log10"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.log10(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="log1p"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.log1p(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="log2"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.log2(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="max"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.max(...inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="min"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.min(...inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="pow"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.pow(inputval.base, inputval.exponent);
        return {
            "result": res
        }
    }else if(stage.action=="random"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let min = Math.ceil(inputval.min);
        let max = Math.floor(inputval.max);
        let res = Math.floor(Math.random() * (max - min + 1) + min);
        return {
            "result": res
        }
    }else if(stage.action=="round"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.random(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="sign"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.sign(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="sin"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.sin(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="sinh"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.sinh(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="sqrt"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.sqrt(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="tan"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.tan(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="tanh"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.tanh(inputval.input);
        return {
            "result": res
        }
    }else if(stage.action=="trunc"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = Math.trunc(inputval.input);
        return {
            "result": res
        }
    }
}