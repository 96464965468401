import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getWebsiteApi, logRouteChangeApi } from "./App.service";


const waitTime = (timeout)=>{
    return new Promise((resolve, reject)=>{
        setTimeout(()=>{
            resolve(true)
        }, timeout)
    })
}


export const debounceLog = createAsyncThunk(
    "toiler/debouncelog",
    async(payload, {dispatch, rejectWithValue})=>{
        await waitTime(100);
        return {
            "status": true
        }
    }
)

export const getWebsite = createAsyncThunk(
    "toiler/getwebsite",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getWebsiteApi(payload);
            return res;
        }catch(error){
            throw error;
        }
    }
)


export const getLogRoutes = createAsyncThunk(
    "apptemplates/getlogroutes",
    async(payload, {dispatch, rejectWithValue, getState})=>{
        try{
            let debounced = getState().toiler.debounced;
            if(!debounced){
                dispatch(setdebounce());
                dispatch(debounceLog());
                let t1 = new Date();
                let resp = await logRouteChangeApi(payload);
                let t2 = new Date();
                if((t2.getTime()-t1.getTime())>100){
                    dispatch(resetdebounce());
                }
                return resp;
            }
        }catch(err){
            console.log("error updating the counter")
        }
    }
)


const AppSlice = createSlice({
    name: "toiler",
    initialState: {
        "website": undefined,
        "debounced": false
    },
    reducers:{
        resetdebounce: (state, action)=>{
            state.debounced = false;
        },
        setdebounce: (state, action)=>{
            state.debounced = true;
        }
    },
    extraReducers: (builder)=>{
        builder.addCase(getWebsite.fulfilled, (state, action)=>{
            //set clientid to localstorage
            localStorage.setItem("clientid", action.payload.clientid);
            state.website = action.payload;

        })
        builder.addCase(getLogRoutes.fulfilled, (state, action)=>{
            state.debounced = true;
        })
        builder.addCase(debounceLog.fulfilled, (state, action)=>{
            state.debounced = false;
        })
    }

})

export const {resetdebounce, setdebounce} = AppSlice.actions;

export const selectWebsite = state => state.toiler.website

export default AppSlice.reducer;