import MarkdownComponent from "./Markdown";


const ToilerElement = ({
                        element,
                        elmkey,
                        indexarr,
                        position
                      })=>{


    return (
        <>
            {element.tag=="markdown"&&
                <MarkdownComponent
                    element={element}
                    elmkey={elmkey}
                    indexarr={indexarr}
                    position={position}
                >
                </MarkdownComponent>
            }
        </>
    )

}


export default ToilerElement;