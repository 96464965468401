import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { showError } from "../error.slice";
import { getEnvironVarApi, getOptionsApi, updateEnvironVarApi, commitEnvironVarApi } from "./environvariable.service";

export const getOptions = createAsyncThunk(
    "environvariables/getoptions",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getOptionsApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting "+payload.type+" options"));
            throw error;
        }
    }
)

export const getEnvironVar = createAsyncThunk(
    "environvariables/getenvironvar",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getEnvironVarApi(payload);
            return {
                "fields": res,
                "source": payload.source,
                "sourceid": payload.sourceid
            };
        }catch(error){
            dispatch(showError("Error getting environ variable"));
            throw error;
        }
    }
)

export const updateEnvironVar = createAsyncThunk(
    "environvariables/updateenvironvar",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await updateEnvironVarApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting environ variable"));
            throw error;
        }
    }
)

export const commitEnvironVar = createAsyncThunk(
    "environvariables/commitEnvironvar",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
           let res = await commitEnvironVarApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error updating environ variables"));
            throw error;
        }
    }
)

const environvariableSlice = createSlice({
    "name": "environvariables",
    initialState: {
        "domains":[],
        "folders": [],
        "templates": [],
        "variableupdated": false,
        "editvar":null,
        "currentworkspacevar": [],
        "currentappvar": [],
        "currentwebsitevar": [],
        "currrentworkspace": "",
        "currentwebsite":"",
        "currentapp": "",
        "vars":{

        }
    },
    "reducers":{
        resethosts: (state, action)=>{
            state.hosts = [];
        },
        resetfolders: (state, action)=>{
            state.folders = [];
        },
        resettemplates: (state, action)=>{
            state.templates = [];
        },
        resetvariableupdated: (state, action)=>{
            state.variableupdated = false
        }   
    },
    extraReducers: (builder)=>{
        builder.addCase(getOptions.fulfilled,(state, action)=>{
            if(action.payload.type=="domain"){
                state.domains = action.payload.resource;
            }else if(action.payload.type=="folder"){
                state.folders = action.payload.resource;
            }else if(action.payload.type=="template"){
                state.templates = action.payload.resource
            }
        })
        builder.addCase(updateEnvironVar.fulfilled, (state, action)=>{
            state.variableupdated = true;
        })
        builder.addCase(getEnvironVar.fulfilled, (state, action)=>{
            if(action.payload.source=="workspace"){
                state.currentworkspacevar = action.payload.fields;
                state.currrentworkspace = action.payload.sourceid;
            }else if(action.payload.source=="app"){
                state.currentappvar = action.payload.fields;
                state.currentapp = action.payload.sourceid;
                state.currentwebsite = "";
            }else if(action.payload.source=="website"){
                state.currentwebsitevar = action.payload.fields;
                state.currentwebsite = action.payload.sourceid;
                state.currentapp = "";
            }
        })
    }
})


export const selectCurrentWorkspacevar = (state) => state.environvariables.currentworkspacevar;
export const selectCurrentAppvar = (state) => state.environvariables.currentappvar;
export const selectCurrentWebsiteVar = (state) => state.environvariables.currentwebsitevar;
export const selectDomains = (state) => state.environvariables.domains;
export const selectFolders = (state) => state.environvariables.folders;
export const selectTemplates = (state) => state.environvariables.templates;
export const selectVariableUpdate = (state) => state.environvariables.variableupdated;


export const {
                resetfolders, 
                resethosts, 
                resettemplates,
                resetvariableupdated
            } = environvariableSlice.actions;

export default environvariableSlice.reducer;