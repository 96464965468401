// import jwt from "jsonwebtoken";
import * as utils from "../utils";

export const executeAction = async(stage, pipeline)=>{
    // if(stage.action=="sign"){
    //     let inputval = utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
    //     let payload = {...inputval.payload};
    //     Object.keys(payload).map((key)=>{
    //         if(key!=="data"){
    //             if(payload[key]==undefined){
    //                 delete payload[key];
    //             }
    //         }
    //     })
    //     let token = jwt.sign(payload, inputval.key);
    //     return {
    //         "token": token
    //     }
    // }else if(stage.action=="verify"){
    //     let inputval = utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
    //     let res = jwt.verify(inputval.token, inputval.key);
    //     return res;
    // }
}