import * as utils from "../utils";

export const executeAction = async (stage, pipeline)=>{
    if(stage.action=="getkey"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let val = sessionStorage.getItem(inputval.key);
        return {
            "result": val
        }
    }else if(stage.action=="setkey"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let key = inputval.key;
        let val = inputval.value;
        sessionStorage.setItem(key, val)
        return {
            "ack": true
        }
    }else if(stage.action=="clear"){
        sessionStorage.clear();
        return {
            "ack": true
        }
    }
}