export const  TypeProps = [
    {
        "type": "button",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "value": false
            },
            {
                "name": "disabled",
                "type": "boolean",
                "value": false
            }
        ],
        "outputtype": null
    },
    {
        "type": "checkbox",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "value": false,
                "default": false
            },
            {
                "name": "disabled",
                "type": "boolean",
                "value": false,
                "default": false
            },
            {
                "name": "required",
                "type": "boolean",
                "value": false,
                "default": false
            }
        ],
        "outputtype": "boolean"
    },
    {
        "type": "color",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "value": false,
                "default": false
            },
            {
                "name": "disabled",
                "type": "boolean",
                "value": false,
                "default": false
            },
            {
                "name": "list",
                "type": "text",
                "value": "",
                "default": ""
            }
        ],
        "outputtype": "string"
    },
    {
        "type": "date",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "disabled",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "min",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "max",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "readonly",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "step",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "datetime"
    },
    {
        "type": "datetime-local",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "disabled",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "min",
                "type": "text",
                "value": "",
                "default": ""
            },
            {
                "name": "max",
                "type": "text",
                "value": "",
                "default": ""
            },
            {
                "name": "readonly",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "value": "",
                "default": ""
            }
        ],
        "outputtype": "datetime"
    },
    {
        "type": "email",
        "props":[
            {
                "name": "disabled",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "minlength",
                "type": "text",
                "value": "",
                "default": ""
            },
            {
                "name": "maxlength",
                "type": "text",
                "value": "",
                "default": ""
            },
            {
                "name": "readonly",
                "type": "boolean",
                "value": false,
                "default": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "value": false,
                "default": false
            },
            {
                "name": "dirname",
                "type": "text",
                "value": "",
                "default": ""
            },
            {
                "name": "pattern",
                "type": "text",
                "value": "",
                "default": ""
            },
            {
                "name": "placeholder",
                "type": "text",
                "value": "",
                "default": ""
            },
            {
                "name": "size",
                "type": "text",
                "value": "",
                "default": ""
            },
            {
                "name": "multiple",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "defalut": "",
                "value": ""
            }
        ],
        "outputtype": "string"
    },
    {
        "type": "file",
        "props":[
            {
                "name": "accept",
                "type": "text",
                "default": "*",
                "value": "*"
            },
            {
                "name": "multiple",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "file"
    },
    {
        "type": "hidden",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "dirname",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "value",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "string"
    },
    {
        "name": "image",
        "props":[
            {
                "name": "alt",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "height",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "src",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "width",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "file"
    },
    {
        "type": "month",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "disabled",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "min",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "max",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "readonly",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "string"
    },
    {
        "type": "number",
        "props":[
            {
                "name": "disabled",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "minlength",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "maxlength",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "readonly",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "dirname",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "pattern",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "placeholder",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "size",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "multiple",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "number"
    },
    {
        "name": "password",
        "props":[
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "disabled",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "maxlength",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "minlength",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "pattern",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "placeholder",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "size",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "string"
    },
    {
        "type": "radio",
        "props":[
            {
                "name": "disabled",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            }
        ],
        "outputtype": "boolean"
    },
    {
        "type": "range",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "step",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "number"
    },
    {
        "type": "reset",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "readonly",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            }
        ],
        "outputtype": null
    },
    {
        "type": "search",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "dirname",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "list",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "maxlength",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "minlength",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "pattern",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "placeholder",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "readonly",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "size",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "string"
    },
    {
        "type": "submit",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "readonly",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            }
        ],
        "outputtype": null
    },
    {
        "type": "tel",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "dirname",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "disabled",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "maxlength",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "minlength",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "pattern",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "placeholder",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "readonly",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "size",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "string"
    },
    {
        "type": "text",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "dirname",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "disabled",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "maxlength",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "minlength",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "pattern",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "placeholder",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "readonly",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "size",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "string"
    },
    {
        "type": "time",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "dirname",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "disabled",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "min",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "max",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "readonly",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "step",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "string"
    },
    {
        "type": "week",
        "props":[
            {
                "name": "autocapitalize",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "dirname",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "disabled",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "min",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "max",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "readonly",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "step",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "string"
    },
    {
        "type": "url",
        "props":[
            {
                "name": "autocomplete",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "dirname",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "disabled",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "list",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "maxlength",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "minlength",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "pattern",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "placeholder",
                "type": "text",
                "default": "",
                "value": ""
            },
            {
                "name": "readonly",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "required",
                "type": "boolean",
                "default": false,
                "value": false
            },
            {
                "name": "size",
                "type": "text",
                "default": "",
                "value": ""
            }
        ],
        "outputtype": "string"
    }
]