import React from "react";
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const VideoElement = (props)=>{

    const videoRef = React.useRef(null);
    const playerRef = React.useRef(null);

    const {options, onReady} = props;
  
    React.useEffect(() => {
  
      // Make sure Video.js player is only initialized once
      if (!playerRef.current) {
        // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode. 
        const videoElement = document.createElement("video-js");
  
        videoElement.classList.add('vjs-big-play-centered');
        videoRef.current.appendChild(videoElement);
  
        const player = playerRef.current = videojs(videoElement, options, () => {
          videojs.log('player is ready');
          onReady && onReady(player);
        });
  
      // You could update an existing player in the `else` block here
      // on prop change, for example:
      } else {
        const player = playerRef.current;
        if(options.autoplay!=undefined&&options.autoplay!=""){
            player.autoplay(options.autoplay);
        }
        if(options.controls!=undefined&&options.controls!=""){
            if(options.controls=="false"){
                player.controls(false);
            }else if(options.controls=="true"){
                player.controls(true);
            }
            
        }
        if(options.width!=undefined&&options.width!=""){
            player.width(options.width)
        }
        if(options.height!=undefined&&options.height!=""){
            player.height(options.height)
        }
        if(options.loop!=undefined&&options.loop!=""){
            player.loop(options.loop)
        }
        if(options.muted!=undefined&&options.muted!=""){
            player.muted(options.muted);
        }
        if(options.poster!=undefined&&options.poster!=""){
            player.poster(options.poster);
        }
        if(options.preload!=undefined&&options.preload!=""){
            player.preload(options.preload);
        }
        player.src(options.sources);
      }
    }, [options, videoRef]);
  
    // Dispose the Video.js player when the functional component unmounts
    React.useEffect(() => {
      const player = playerRef.current;
  
      return () => {
        if (player && !player.isDisposed()) {
          player.dispose();
          playerRef.current = null;
        }
      };
    }, [playerRef]);

    return (    
        <>
            <div data-vjs-player>
                <div ref={videoRef} />
            </div>
        </>
    )
}

export default VideoElement;