import * as utils from "../utils";


export const executeAction = async(stage, pipeline)=>{
    if(stage.action=="exec"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let ptr;
        if(inputval.flags!=""&&inputval.flags!=undefined&&inputval.flags!=null){
            ptr = new RegExp(inputval.pattern, inputval.flags);
        }else{
            ptr = new RegExp(inputval.pattern);
        }
        let res = ptr.exec(inputval.string);
        return {
            "result": res
        }
    }else if(stage.action=="test"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let ptr;
        if(inputval.flags!=""&&inputval.flags!=undefined&&inputval.flags!=null){
            ptr = new RegExp(inputval.pattern, inputval.flags);
        }else{
            ptr = new RegExp(inputval.pattern);
        }
        let res = ptr.test(inputval.string);
        return {
            "result": res
        }
    }else if(stage.action=="match"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let ptr;
        if(inputval.flags!=""&&inputval.flags!=undefined&&inputval.flags!=null){
            ptr = new RegExp(inputval.pattern, inputval.flags);
        }else{
            ptr = new RegExp(inputval.pattern);
        }
        let res = inputval.string.match(ptr);
        return {
            "result": res
        }
    }else if(stage.action=="matchall"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let ptr;
        if(inputval.flags!=""&&inputval.flags!=undefined&&inputval.flags!=null){
            ptr = new RegExp(inputval.pattern, inputval.flags);
        }else{
            ptr = new RegExp(inputval.pattern, 'g');
        }
        let res = [...inputval.string.matchAll(ptr)];
        return {
            "result": res
        }
    }else if(stage.action=="search"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let ptr;
        if(inputval.flags!=""&&inputval.flags!=undefined&&inputval.flags!=null){
            ptr = new RegExp(inputval.pattern, inputval.flags);
        }else{
            ptr = new RegExp(inputval.pattern);
        }
        let res = inputval.string.search(ptr);
        return {
            "result": res
        }
    }else if(stage.action=="replace"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let ptr;
        if(inputval.flags!=""&&inputval.flags!=undefined&&inputval.flags!=null){
            ptr = new RegExp(inputval.pattern, inputval.flags);
        }else{
            ptr = new RegExp(inputval.pattern);
        }
        let res = inputval.string.replace(ptr, inputval.replacement);
        return {
            "result": res
        }
    }else if(stage.action=="replaceAll"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let ptr;
        if(inputval.flags!=""&&inputval.flags!=undefined&&inputval.flags!=null){
            ptr = new RegExp(inputval.pattern, inputval.flags);
        }else{
            ptr = new RegExp(inputval.pattern, 'g');
        }
        let res = inputval.string.replaceAll(ptr, inputval.replacement);
        return {
            "result": res
        }
    }
}