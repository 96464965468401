import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const timeout = ()=>{
    return new Promise((resolve, reject)=>{
        setTimeout(3000,()=>{
            resolve()
        })
    })
}


export const showError = createAsyncThunk(
    "error/showerror",
    async(payload,{dispatch, getState})=>{
        let state = getState();
        let errorindex = state.error.errors;
        dispatch(adderror(payload));
        await timeout();
        dispatch(removeerror(errorindex))
    }   
)


const errorSlice = createSlice({
    name: "errors",
    initialState:{
        errors: [],
    },
    reducers:{
        adderror: (state,action)=>{
            state.errors.push(action.payload);
        },
        removeerror: (state, action)=>{
            state.errors.splice(action.payload,1);
        }

    }
})

export const selectErrors = (state)=> state.error.errors;

export const {adderror, removeerror} = errorSlice.actions;

export default errorSlice.reducer;