import * as utils from "../utils";


export const executeAction = async (stage, pipeline)=>{
    if(stage.action=="parse"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let valtoparse = inputval.payload.trim();
        let res = JSON.parse(valtoparse.toString());
        return {
            "result": res
        }
    }else if(stage.action=="stringify"){
        let inputval = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let res = JSON.stringify(inputval.payload);
        return {
            "result": res
        }
    }
}