import axios from "axios";
import * as utils from "../utils";
import * as _ from "lodash";

const checkNestedFiles = (inputschema, nested)=>{
    for(let i=0; i< inputschema.length; i++){
        if(inputschema[i].type=="file"){
            nested=true;
        }
        if(inputschema[i].subschema.length>0){
            if(inputschema[i].type=="array"){
                nested = checkNestedFiles(inputschema[i].subschema[0].subschema, nested)
            }
            if(inputschema[i].type=="object"){
                nested = checkNestedFiles(inputschema[i].subschema, nested);
            }
        }
    }
    return nested;
}

const handleMultipartRequests = (inputschema)=>{
    let multipart = false;
    for(let i=0; i< inputschema.length; i++ ){
        if(inputschema[i].type=="file"){
            multipart = true;
        }else{
            let nested = false;
            checkNestedFiles(inputschema[i].subschema,nested);
            if(nested){
                throw new Error("Nested file is not allowed multipart form")
            }
        }
    }
    return multipart;

}



const constructForm = (body, bodySchema)=>{
    let formData = new FormData();
    for(let i=0; i< bodySchema.length; i++){
        formData.append(bodySchema[i].key,body[bodySchema[i].key]);
    }
    return formData;
}



export const executeAction = async (stage,pipeline, debug)=>{
    if(stage.action=="GET"){
        //check whether atleast one output exists
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let url = input.url;
        let urlparams = input.urlparams;
        if(urlparams!=undefined&&urlparams.length>0){
            for(let i=0; i < urlparams.length; i++){
                url = url+"/"+urlparams[i]
            }
        }
        let querystring = new URLSearchParams(input.query);
        if(querystring!=""){
            url = url+"?"+querystring;
        }
        let res = await axios.get(url , {data: input.body , "headers":input.headers});
        return {
                "result":res.data
               };
        
    }else if(stage.action=="POST"){
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let querystring = new URLSearchParams(input.query);
        let url = input.url;
        let urlparams = input.urlparams;
        if(urlparams!=undefined&&urlparams.length>0){
            for(let i=0; i < urlparams.length; i++){
                url = url+"/"+urlparams[i]
            }
        }  
        if(querystring!=""){
            url = url+"?"+querystring;
        }
        
        let bodyschemaindex = _.findIndex(stage.inputschema, (sc)=>{return sc.key=="body"})

        let multipart = handleMultipartRequests(stage.inputschema[bodyschemaindex].subschema);
        let res;

        if(multipart){
            let formdata = constructForm(input.body, stage.inputschema[bodyschemaindex].subschema);
            res = await axios.post(url,formdata,{"headers":input.headers});
            return {
                "result":res.data
               };
        }else{
            res = await axios.post(url, input.body,{"headers": input.headers});
            return {
                "result":res.data
               };
        }
    }else if(stage.action=="PUT"){
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let querystring = new URLSearchParams(input.query);
        let url = input.url;
        let urlparams = input.urlparams;
        if(urlparams!=undefined&&urlparams.length>0){
            for(let i=0; i < urlparams.length; i++){
                url = url+"/"+urlparams[i]
            }
        }
        if(querystring!=""){
            url = url+"?"+querystring;
        }
        let bodyschemaindex = _.findIndex(stage.inputschema, (sc)=>{return sc.key=="body"})
        let multipart = handleMultipartRequests(stage.inputschema[bodyschemaindex].subschema);
        let res;
        if(multipart){
            let formdata = constructForm(input.body, stage.inputschema[bodyschemaindex].subschema);
            res = await axios.post(url,{"data":formdata, "headers":input.headers});
            return {
                "result":res.data
               };
        }else{
            res = await axios.post(url, {"data":input.body,"headers": input.headers});
            return {
                "result":res.data
               };
        }
        
    }else if(stage.action=="DELETE"){
        let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
        let querystring = new URLSearchParams(input.query);
        let url = input.url;
        let urlparams = input.urlparams;
        if(urlparams!=undefined&&urlparams.length>0){
            for(let i=0; i < urlparams.length; i++){
                url = url+"/"+urlparams[i]
            }
        }
        if(querystring!=""){
            url = url+"?"+querystring;
        }
        let res = await axios.delete(url);
        return {
            "result":res.data
           };
    }else if(stage.action=="FILE_DOWNLOAD"){
        try{
            let input = await utils.parseSchema(stage.inputschema, stage.inputschemamapping, pipeline);
            let url = input.url;
            let res = await axios.request({
                url: url,
                responseType: "blob",
                onDownloadProgress: function({loaded, total, progress, bytes, estimated, rate, download = true}){
                    console.log(loaded, total, progress);
                }
            });
            // let res = await axios.get(url,{responseType: "blob"});
            // let filename = utils.getFilename(res.headers["content-type"]);
            // var finalPath = path.join(env.uploaddest, filename);
            // var buff = buffer.Buffer(res.data);
            // var buffsize = Buffer.byteLength(buff);
            // // console.log(Buffer.byteLength(buff));
            // // const writeStream = fs.createWriteStream(finalPath);
            // // res.data.pipe(writeStream);
            
            // try {
            //     fs.writeFileSync(finalPath, res.data, 'utf8');
            //   } catch (err) {
            //     /* Handle the error */
            //     throw new Error(err);
            //   }
            
            return ;
        }catch(error){
            console.log(error);
        }
        
    }
}